.header {
  position: relative;
  height: 33px;
  padding: 16px 0;
  background-color: #b6b3ec;

  .header-logo {
    width: 120px;
    position: absolute;
    left: 60px;

    @media (max-width: 600px) {
      left: 34px;
    }
  }

  .header-text {
    font-family: National-Extrabold;
    position: absolute;
    right: 60px;
    font-size: 22px;
    cursor: pointer;
    &:hover {
      color: #b5dbff;
    }

    @media (max-width: 600px) {
      right: 34px;
    }
  }
}
