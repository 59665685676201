.footer {
  position: absolute;
  height: 121px;
  width: 100%;
  bottom: 0;
  padding: 16px 0;
  background-color: white;
  color: #1a294a;

  @media (max-width: 600px) {
    height: 210px;
  }

  @media (max-height: 500px) {
    position: relative;
  }

  .footer-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;

    @media (max-width: 600px) {
      flex-wrap: wrap;
    }

    .logo-container {
      width: 23%;
      text-align: center;

      @media (max-width: 600px) {
        width: 55%;
      }

      .logo {
        width: 60px;

        @media (max-width: 600px) {
          position: unset;
        }
      }
    }
    .resources-container {
      width: 30%;
      @media (max-width: 600px) {
        width: 23%;
      }

      h2 {
        color: #1a294a;
      }

      .links {
        margin-left: 21px;

        a {
          text-decoration: none;
          color: #b6b3ec;
        }

        p {
          line-height: 10px;
          font-size: 20px;
        }
      }
    }

    .text-container {
      width: 47%;
      display: flex;
      text-align: center;

      @media (max-width: 600px) {
        width: 100%;
      }

      .text {
        font-family: National-Medium;
        text-align: center;
        margin: auto;

        @media (max-width: 600px) {
          position: unset;
        }
      }
    }
  }
}
