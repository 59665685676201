.expand-list-item {
  display: flex;
  flex-direction: column;

  .toggle-container {
    background-color: white;
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon-container {
      width: 40px;
      right: 0;
      display: inline-table;
      position: relative;

      @media (max-width: 450px) {
        position: absolute;
      }

      .toggle-icon {
        position: absolute;
        width: 20px;
        bottom: -14px;
        margin-left: 12px;
        transition: transform 300ms linear;

        &.rotate {
          transform: rotate(90deg);
          transition: transform 300ms linear;
        }

        @media (max-width: 450px) {
          margin-left: 0;
        }
      }
    }

    .item-title {
      display: inline-block;

      @media (max-width: 450px) {
        width: 90%;
      }
    }
  }

  .item-body {
    background-color: white;

    &.closed {
      transition: all 500ms ease-in-out;
      max-height: 0;
      visibility: hidden;
    }

    &.open {
      visibility: visible;
      max-height: 900px;
      transition: all 500ms ease-in-out;
    }
  }
}
