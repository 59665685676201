.home {
  position: relative;
  height: 100%;

  .main-container {
    background-color: #b6b3ec;
    height: 100%;

    @media (max-height: 500px) {
      margin-top: -65px;
      padding-bottom: 50px;
    }

    .logo-container {
      display: flex;
      justify-content: center;
      height: 400px;

      @media (min-width: 600px) and (max-width: 800px) {
        height: 360px;
      }

      .mindstill-logo {
        height: 60px;
        margin: auto;

        @media (max-width: 1000px) {
          // height: 400px;
        }
      }
    }

    .download-container {
      margin-top: -121px;
      text-align: center;
      flex-direction: column;

      .download-text {
        margin-bottom: 30px;
        font-size: 22px;
      }

      .download-buttons-container {
        display: flex;
        justify-content: center;

        .download-button {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 16px;
          height: 64px;
          background-color: white;
          margin: 0 12px;
          width: 190px;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 15px 40px 5px;

          .apple-logo {
            height: 39px;
          }

          .google-play-logo {
            height: 66px;
          }
        }
      }
    }
  }
}
