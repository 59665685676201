.faq {
  color: #1a294a;
  background-color: white;
  padding-bottom: 230px;

  @media (max-width: 800px) {
    padding-bottom: 230px;
  }

  .faq-header {
    padding: 0 20px;
    margin-bottom: -20px;
  }

  .qna-container {
    padding: 36px;

    @media (max-width: 800px) {
      margin-bottom: 56px;
    }

    @media (max-width: 400px) {
      margin-bottom: 181px;
    }

    &.margin-mobile {
      margin-bottom: 0;
    }

    .padding-left {
      padding-left: 24px;
    }
  }
}
