html,
body,
#root {
  height: 100%;
}

.App {
  //colors
  color: white;
  height: 100%;
  width: 100%;

  @media (max-width: 400px) {
    height: 885px;
  }

  @media (min-width: 550px) and (max-width: 820px) {
    height: 600px;
  }

  //spacing
  .padding {
    padding: 21px;

    @media (min-width: 500px) {
      padding: 24px 55px;
    }
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  //text styling
  font-family: "Poppins-Black";

  // BLACK: "Poppins-Black",
  // EXTRABOLD: "Poppins-ExtraBold",
  // BOLD: "Poppins-Bold",
  // MEDIUM: "Poppins-Medium",
  // REGULAR: "Poppins-Regular",
  // LIGHT: "Poppins-Light",
  // PARAGRAPH: "WorkSans-Regular",

  .bold {
    font-family: "Poppins-Bold";
  }
  .italic {
    font-style: italic;
  }
  .underline {
    text-decoration-line: underline;
  }

  ul {
    margin-block-start: 0px;
    padding-inline-start: 20px;
    line-height: 21px;

    li a {
      color: #b6b3ec;
      font-size: 18px;
      line-height: 33px;
    }
  }

  h1,
  h2 {
    font-family: "Poppins-Bold";
    line-height: 26px;
  }

  p {
    line-height: 21px;
    font-size: 18px;
  }
}
