.references {
  color: #1a294a;
  background-color: white;

  &.mobile-padding {
    padding-bottom: 300px;
  }

  .references-container {
    padding-bottom: 27px;
  }
}
