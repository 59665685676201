body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: National-Bold;
  src: url('./assets/fonts/National-Bold.otf');
}

@font-face {
  font-family: National-Extrabold;
  src: url('./assets/fonts/National-Extrabold.otf');
}

@font-face {
  font-family: National-Medium;
  src: url('./assets/fonts/National-Medium.otf');
}

@font-face {
  font-family: National-Regular;
  src: url('./assets/fonts/National-Regular.otf');
}
